import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OverflowTip from '../../common/OverflowTip';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

function Footer() {

    const [quot, setQuot] = useState({})

    // useLayoutEffect(() => {
    //     _.delay(() => {
    //         axios.get("https://api.quotable.io/random?tags=famous-quotes|competition|courage|creativity|education|ethics|faith|gratitude|inspirational|leadership|motivational|perseverance|success|time|wisdom&maxLength=160").then(res => {
    //             setQuot({ content: res?.data?.content, author: res?.data?.author });
    //         })
    //     }, 1000)

    // }, [])

    return (
        <div className="footer fixed bottom-0  leading-10  border-t border-scogoddd w-full z-40 flex items-center justify-between">

            <p className='ml-6 w-3/4 text-scogoprimary font-medium italic' >
                <OverflowTip placement="top-end" someLongText={
                    <>
                        "{quot?.content}" {" "}
                        <span className='not-italic text-black' >
                            - {quot?.author}
                        </span>
                    </>
                } />
            </p>
            <div className=" px-5 mr-4">
                <p className="text-scogoprimary mt-1 font-medium sidebarFont"><span className="lg:inline-block hidden"> Copyright © {moment().year()} Scogo. All Rights Reserved. </span> </p>
            </div>
        </div>
    )
}

export default Footer
