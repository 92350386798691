import React, { useState } from 'react';
import IconToolTip from '../../common/IconToolTip';
import { isMobileView } from '../../pages/auth/components/NewScogoButton';

export const RibbonContainer = ({ children, headerLabel, isCollapsible = false, openCardDefault = true, padding = 'p-2', childrenClass = 'justify-around -mt-[0.7rem] w-full' }) => {

    const [openCard, setOpenCard] = useState(openCardDefault);
    return (<>  <div
        className={`relative w-full border pb-3 p-2 bg-white`}
        style={{
            'box-shadow': ' 0px 3px 6px #00000029',
            'border-radius': '10px',
            overflowY: 'auto',
            minHeight: isMobileView ? 'max-content' : 'auto',
        }}
    >
        <div
            className='ribbon col-span-2'

        >
            <span className='ribbon3 bg-scogobgsky ribbonblue'>{headerLabel}</span>
            {isCollapsible && <div className='absolute right-2 flex gap-x-3 top-1' onClick={() => { setOpenCard(!openCard) }}>
                <IconToolTip title={openCard ? 'Close' : ' Open'}>
                    <span className='material-icons flex cursor-pointer text-font24  mr-4 text-scogoprimary  hover:text-scogoorange'>
                        {openCard ? 'keyboard_arrow_up' : 'expand_more'}
                    </span>
                </IconToolTip>
            </div>}
        </div>
        {openCard && <div className={childrenClass}>

            {children}
        </div>}
    </div>
    </>)
}