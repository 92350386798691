import EditInventorySite from '../Modals/Inventory/EditInventorySite';
import EditInventoryAsset from '../Modals/Inventory/EditInventoryAsset';
import AddOwner from '../Modals/Inventory/AddOwner';
import UpdateSla from '../Modals/Inventory/UpdateSla';
import MigrateAsset from '../Modals/Inventory/MigrateAsset';
import ReplaceAsset from '../Modals/Inventory/ReplaceAsset';
import EditCategoriseAsset from '../Modals/Inventory/EditCategoriseAsset';
import EditInventoryWarehouse from '../Modals/Inventory/EditInventoryWarehouse';
import EditInventorySpareAsset from '../Modals/Inventory/EditInventorySpareAsset';
import AddSpareAsset from '../Modals/Inventory/AddSpareAsset';
import DownloadQR from '../Modals/Inventory/DownloadQR';
import ScrapAsset from '../Modals/Inventory/ScrapAsset';
import UploadFile from '../Modals/Inventory/UploadFile';
import AddAssetTypes from '../Modals/Inventory/AddAssetTypes';
import ApproveRmaSupport from '../Modals/Inventory/ApproveRmaSupport';
import CreateReconcileTicketSpareAsset from '../Modals/Inventory/CreateReconcileTicketSpareAsset';
import CreateSupportTicket from '../Modals/Inventory/CreateSupportTicket';
import { openCustomModal, openTextModalAction, closeModalAction } from '../../actions/modal';
import {
    deleteInventorySite,
    deleteInventoryAsset,
    deleteInventoryWarehouse,
    deleteInventorySpareAsset,
    bulkUploadSites,
    bulkUploadAssets,
    bulkUploadSpareAssets,
} from '../../actions/inventory';
import { isScm, isCustomer, isCustomerAdmin, isCustomerUser, isCluster, isPsp, isSuperVisor, isSp, isFe, isIsp, isSpIsp } from '../../utils/role';
import { useSelector } from 'react-redux';
import DownloadReport from '../Modals/Inventory/DownloadReport';
import { sendToastMessage } from '../../actions/toast';
import DownloadBulkQR from '../Modals/Inventory/DownloadBulkQR';
import { isArrayElementsSame } from '../../utils/common';
import AddAsset from '../Ticket/Modal/AddAsset';
import AccordionJSON from './reusableComponents/AccordionJSON';
import ButtonScogoOrange from '../../common/Buttons/ButtonScogoOrange';
import { isAssetActionsAllowed } from '../../utils/access';

// Sites Table Actions
const editSite = {
    key: 'edit_site',
    menuName: 'Edit Site',
    menuIcon: 'save_as',
    onClick: (site, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Edit Site',
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <EditInventorySite modalData={site} />,
            })
        );
    },
};

const updateSla = {
    key: 'update_sla',
    menuName: 'Update SLA',
    menuIcon: 'app_registration',
    onClick: (site, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Update SLA',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <UpdateSla modalData={site} customerId={customerId} />,
            })
        );
    },
};

const addAsset = {
    key: 'add_asset',
    menuName: 'Add Asset',
    menuIcon: 'list',
    onClick: (site, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Add Asset',
                modalWidth: '95rem',
                modalHeight: 'auto',
                modalComponent: <AddAsset siteId={site.id} />,
            })
        );
    },
};

const raiseSupportTicket = {
    key: 'create_ticket',
    menuName: 'Create Ticket',
    menuIcon: 'confirmation_number',
    onClick: (site, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Raise Support Ticket',
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <CreateSupportTicket modalData={site} customerId={customerId} />,
            })
        );
    },
};

const deleteSiteModal = {
    key: 'delete_site',
    menuName: 'Delete Site',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (site, dispatch, { customerId }) => {
        return dispatch(
            openTextModalAction({
                heading: 'Delete Site',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: 'deleteSite',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(deleteInventorySite({ siteId: site?.id, customerId: customerId, formName: 'deleteSite' }));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

// Assets Table Actions

const addOwner = {
    key: 'add_owner',
    menuName: 'Add Owner',
    menuIcon: 'add_business',
    onClick: (asset, dispatch, { siteId, customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Add Owner',
                modalWidth: '44rem',
                modalHeight: 'auto',
                modalComponent: <AddOwner modalData={asset} customerId={customerId} siteId={siteId} />,
            })
        );
    },
};

const replaceAsset = {
    key: 'replace_asset',
    menuName: 'Replace Asset',
    menuIcon: 'find_replace',
    onClick: (asset, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Replace Asset',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <ReplaceAsset modalData={asset} customerId={customerId} />,
            })
        );
    },
};

const categoriseAsset = {
    key: 'categorize_asset',
    menuName: 'Categorise Asset',
    menuIcon: 'category',
    onClick: (asset, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Categorise Asset',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <EditCategoriseAsset modalData={{ assetslist: [asset] }} customerId={customerId} />,
            })
        );
    },
    isDisabled: (asset) => {
        return { disabled: asset?.is_categorised !== 0, message: 'Not allowed to modify warehouse' };
    },
};

const assetRaiseSupportTicket = {
    key: 'create_tickets',
    menuName: 'Create Tickets',
    menuIcon: 'confirmation_number',
    onClick: (asset, dispatch, { customerId, siteId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Raise Support Ticket',
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <CreateSupportTicket modalData={asset} customerId={customerId} siteId={siteId} />,
            })
        );
    },
};

const downloadQR = {
    key: 'download_qr_code',
    menuName: 'Download QR Code',
    menuIcon: 'qr_code_2',
    onClick: (asset, dispatch, { customerId, customerName }) => {
        return dispatch(
            openCustomModal({
                heading: 'Download QR Code',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadQR assetslist={[asset]} modalData={asset} customerId={customerId} customerName={customerName} />,
            })
        );
    },
};

const scrapAsset = {
    key: 'scrap_asset',
    menuName: 'Scrap Asset',
    menuIcon: 'backup',
    divider: true,
    onClick: (asset, dispatch, { customerId, tabName, siteId }) => {
        return dispatch(
            openCustomModal({
                heading: `Scrap Asset | ${getAssetTitle(asset)}`,
                modalWidth: '45rem',
                modalHeight: 'auto',
                modalComponent: <ScrapAsset modalData={{ assetslist: [asset] }} customerId={customerId} modalName={tabName} siteId={siteId} />,
            })
        );
    },
    isDisabled: (asset) => {
        return { disabled: asset?.is_archived !== 0, message: 'Scrap asset not archived' };
    },
};

const EditConfigButton = ({ asset, dispatch }) => {
    return <ButtonScogoOrange textOrComponent={`${isDeviceConfigurationRequired(asset) ? "Edit Config" : "Device Files"}`} onClick={() => editAssetConfig.onClick(asset, dispatch)} />
}


const editAsset = {
    key: 'edit_asset',
    menuName: 'Edit Asset',
    menuIcon: 'save_as',
    onClick: (asset, dispatch, { siteId, customerId }) => {

        return dispatch(
            openCustomModal({
                heading: (
                    <span className='text-black'>
                        Edit Asset | <span className='text-scogobgsky text-font16'>{getAssetTitle(asset)}</span>
                    </span>
                ),
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <EditInventoryAsset modalData={asset} customerId={customerId} siteId={siteId} renderButtons={<EditConfigButton asset={asset} dispatch={dispatch} />} />,
            })
        );
    },
};



const editAssetConfig = {
    key: 'edit_config',
    conditionalRendering: (asset, _) => {
        if (isDeviceConfigurationRequired(asset)) return { menuName: 'Asset Config', menuIcon: 'settings_applications', iconColor: 'text-scogogray' };
        return { menuName: 'Device Files', menuIcon: 'settings_applications', iconColor: 'text-scogogray' };
    },
    onClick: (asset, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: (
                    <span className='text-black'>
                        Edit Asset Config | <span className='text-scogobgsky text-font16'>{getAssetTitle(asset)}</span>
                    </span>
                ),
                modalWidth: '70rem',
                modalHeight: '84vh',
                maxHeight: '84vh',
                modalComponent: <AccordionJSON asset={asset} assetId={asset.id} serial_number={asset.serialNo} showJsonEditor={isDeviceConfigurationRequired(asset)} enduserId={asset.enduserId} />,
            })
        );
    },
};

const deleteAsset = {
    key: 'delete_asset',
    menuName: 'Delete Asset',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (asset, dispatch, { customerId }) => {
        const formName = 'deleteAsset';
        return dispatch(
            openTextModalAction({
                heading: `Delete Asset | ${getAssetTitle(asset)}`,
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        onClick: () => {
                            dispatch(deleteInventoryAsset({ assetId: asset.id, customerId, formName, enduserId: asset.enduserId }));
                        },
                        buttonClass: 'hover:bg-scogoLightFillRed',
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

//warehouse
const editWarehouse = {
    key: 'edit_warehouse',
    menuName: 'Edit Warehouse',
    menuIcon: 'save_as',
    onClick: (warehouse, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Edit Warehouse',
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <EditInventoryWarehouse modalData={warehouse} customerId={customerId} />,
            })
        );
    },
    isDisabled: (warehouse, prop) => {
        const { userId, role, type, customerId } = prop
        if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type)) return { disabled: false, message: '' };
        if (isCustomer(role, type)) {
            return { disabled: !canModifyWarehouse(customerId, warehouse?.customer_id), message: 'Not allowed to modify warehouse' };
        }
        return { disabled: !canModifyWarehouse(userId, warehouse?.fk_user_id), message: 'Not allowed to modify warehouse' };
    },
};

const addSpareAsset = {
    key: 'add_spare_asset',
    menuName: 'Add Spare Assets',
    menuIcon: 'queue_play_next',
    onClick: (warehouse, dispatch, { customerId, userId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Add Spare Assets',
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <AddSpareAsset modalData={warehouse} customerId={customerId} userId={userId} />,
            })
        );
    },
    isDisabled: (warehouse, { userId, role, type }) => {
        if (isScm(role, type)) return { disabled: false, message: '' };
        return { disabled: !canModifyWarehouse(userId, warehouse?.fk_user_id), message: 'Not allowed to modify warehouse' };
    },
};

const isToOpenModal = (selectedData) => {
    let isItemsSelected = Array.isArray(selectedData) && selectedData.length > 0;
    let toastErrorMsg = '';

    let customerIdsOfSpareAssets = Array.isArray(selectedData) && selectedData.map((spareasset) => spareasset?.customer_id);
    let warehouseIdsOfSpareAssets = Array.isArray(selectedData) && selectedData.map((spareasset) => spareasset?.warehouse?.id);

    if (!isItemsSelected) {
        toastErrorMsg = 'Please select atleast one';
    } else {
        if (!isArrayElementsSame(customerIdsOfSpareAssets)) {
            toastErrorMsg = 'Multiple customer assets found';
        }
        if (!isArrayElementsSame(warehouseIdsOfSpareAssets)) {
            toastErrorMsg = 'Multiple warehouse assets found';
        }
    }

    let openModal = isItemsSelected && isArrayElementsSame(customerIdsOfSpareAssets) && isArrayElementsSame(warehouseIdsOfSpareAssets);

    return { toastErrorMsg, openModal };
};

const createReconcilationTicketSpareAsset = {
    key: 'create_reconcilation_ticket_spareasset',
    menuName: 'Create Reconcilation Ticket',
    menuIcon: 'confirmation_number',
    divider: true,
    onClick: (warehouse, dispatch, { warehouseId, siteId, customerId, getSelectedData }) => {
        let selectedData = getSelectedData();
        let { openModal, toastErrorMsg } = isToOpenModal(selectedData && selectedData);
        if (openModal) {
            return dispatch(
                openCustomModal({
                    heading: 'Create Reconcilation Ticket',
                    modalWidth: '90rem',
                    modalHeight: 'auto',
                    modalComponent: (
                        <CreateReconcileTicketSpareAsset
                            modalData={{ assetslist: selectedData }}
                            customerId={customerId}
                            warehouseId={warehouseId}
                            siteId={siteId}
                            warehouse={warehouse}
                        />
                    ),
                })
            );
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: toastErrorMsg }));
        }
    },
};

const deleteWarehouseModal = {
    key: 'delete_warehouse',
    menuName: 'Delete Warehouse',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (warehouse, dispatch, { customerId }) => {
        const formName = 'deleteWarehouse';
        return dispatch(
            openTextModalAction({
                heading: 'Delete Warehouse',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(deleteInventoryWarehouse({ warehouseId: warehouse?.id, customerId, formName }));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                        buttonClass: 'hover:bg-scogobgsky',
                    },
                ],
            })
        );
    },
    isDisabled: (warehouse, { userId, role, type }) => {
        if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type)) return { disabled: false, message: '' };
        return { disabled: !canModifyWarehouse(userId, warehouse?.fk_user_id), message: 'Not allowed to delete warehouse' };
    },
};

//spareAssets

const editAssetModal = {
    key: 'edit_spareasset',
    menuName: 'Edit Spare Asset',
    menuIcon: 'save_as',
    onClick: (asset, dispatch, { customerId, warehouseId }) => {
        return dispatch(
            openCustomModal({
                heading: `Edit Spare Asset | ${asset.spare_number}`,
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <EditInventorySpareAsset modalData={asset} customerId={customerId} warehouseId={warehouseId} renderButtons={<EditConfigButton asset={asset} dispatch={dispatch} />} />,
            })
        );
    },
    isDisabled: (asset, { userId, role, type }) => {
        if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isCustomer(role, type)) return { disabled: false, message: '' };
        return { disabled: !canModifyWarehouse(userId, asset?.warehouse?.fk_user_id), message: 'Not allowed to modify ' };
    },
};

const migrateAsset = {
    key: 'migrate_asset',
    menuName: 'Migrate Asset',
    menuIcon: 'move_up',
    onClick: (asset, dispatch, { customerId }) => {
        const warehouseId = asset?.warehouse_id > 0 ? asset?.warehouse_id : 0;
        const siteId = asset?.fk_site_id > 0 ? asset?.fk_site_id : 0;
        return dispatch(
            openCustomModal({
                heading: `Migrate Asset`,
                modalWidth: '85rem',
                modalHeight: 'auto',
                modalComponent: <MigrateAsset modalData={{ assetslist: [asset] }} customerId={customerId} warehouseId={warehouseId} siteId={siteId} />,
            })
        );
    },
    isDisabled: (asset, { userId, tabName, role, type }) => {
        if (tabName === spareAssets?.id) {
            if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isCustomer(role, type))
                return { disabled: !canAssetMigrate(asset?.status), message: 'Can only migrate Available assets' };
            return {
                disabled: !canModifyWarehouse(userId, asset?.warehouse?.fk_user_id) || !canAssetMigrate(asset?.status),
                message: 'Not allowed to modify ',
            };
        } else if (tabName === assets?.id) {
            return { disabled: !canAssetMigrate(asset?.status), message: 'Not allowed to modify ' };
        }
    },
};

const requestRmaSupport = {
    key: 'request_rma_support',
    menuName: 'Request Rma support',
    menuIcon: 'list',
    onClick: (asset, dispatch, { customerId }) => {
        const siteId = asset?.fk_site_id > 0 ? asset?.fk_site_id : 0;
        return dispatch(
            openCustomModal({
                heading: 'Request RMA Support',
                modalWidth: '64rem',
                modalHeight: 'auto',
                modalComponent: <ApproveRmaSupport modalData={{ assetslist: [asset] }} customerId={customerId} rmaEligibility={'RMA_ELIGIBILITY_REQUEST'} siteId={siteId} />,
            })
        );
    },
};

const approveRmaSupport = {
    key: 'approve_rma_support',
    menuName: 'Approve Rma support',
    menuIcon: 'list',
    onClick: (asset, dispatch, { customerId }) => {
        const siteId = asset?.fk_site_id > 0 ? asset?.fk_site_id : 0;
        return dispatch(
            openCustomModal({
                heading: 'Approve RMA Support',
                modalWidth: '64rem',
                modalHeight: 'auto',
                modalComponent: <ApproveRmaSupport modalData={{ assetslist: [asset] }} customerId={customerId} rmaEligibility={'RMA_ELIGIBILITY_APPROVE'} siteId={siteId} />,
            })
        );
    },
    isDisabled: (asset) => {
        return { disabled: asset?.is_rma_eligible !== 0, message: 'Not eligible to approve' };
    },
};

const deleteSpareAssetModal = {
    key: 'delete_spareasset',
    menuName: 'Delete Spare Asset',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (asset, dispatch, { customerId, warehouseId }) => {
        const spareAssetID = asset?.id;
        const formName = 'deleteSpareAsste';
        return dispatch(
            openTextModalAction({
                heading: 'Delete Spare Asset',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(deleteInventorySpareAsset({ assetId: spareAssetID, customerId, warehouseId, formName }));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
    isDisabled: (asset, { userId, role, type }) => {
        if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isCustomer(role, type)) return { disabled: false, message: '' };
        return {
            disabled: !canModifyWarehouse(userId, asset?.warehouse?.fk_user_id) || !canAssetMigrate(asset?.status),
            message: 'Not allowed to delete',
        };
    },
};

//header bulk actions
const bulkUploadSite = {
    key: 'bulk_site_modal',
    menuName: 'Bulk Upload Site',
    menuIcon: 'inventory',
    onClick: (data, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Bulk Upload Site',
                modalWidth: '64rem',
                modalHeight: 'auto',
                modalComponent: <UploadFile customerId={customerId} uploadFileAction={bulkUploadSites} type={sites.id} filename={'bulk_upload_site_sample'} showCustomerDropdown />,
            })
        );
    },
};

const downloadBulkQR = {
    key: 'download_qrcodes',
    menuName: 'Download QR Codes',
    menuIcon: 'qr_code_2',
    onClick: (asset, dispatch, { getSelectedData, customerId, defaultEmail, tab, customerIdOfSelectedSite }) => {
        let selectedData = getSelectedData();
        return dispatch(
            openCustomModal({
                heading: 'Asset QR Code',
                modalWidth: '45rem',
                modalHeight: 'auto',
                selectedCount: selectedData.length,
                modalComponent: (
                    <DownloadBulkQR tab={tab} customerIdOfSelectedSite={customerIdOfSelectedSite} customerId={customerId} defaultEmail={defaultEmail} assetslist={selectedData} />
                ),
            })
        );
    },
};

const archivedOrViewAllAssets = {
    key: 'archived_view_or_all_assets',
    conditionalRendering: (item, dispatch, { isArchiveAssetSelected }) => {
        if (isArchiveAssetSelected) return { menuName: 'View All Assets', menuIcon: 'arrow_back' };
        return { menuName: 'Archived Assets', menuIcon: 'archive' };
    },
    onClick: (item, dispatch, { goToAssetTab, setIsArchiveAssetSelected, isArchiveAssetSelected }) => {
        setIsArchiveAssetSelected(!isArchiveAssetSelected);
        let isArchive = false;
        if (!isArchiveAssetSelected) isArchive = true;
        goToAssetTab('', isArchive);
    },
};

const isToOpenBulkActions = ({ selectedData }) => {
    let isItemsSelected = Array.isArray(selectedData) && selectedData.length > 0;
    let toastErrorMsg = 'Please select atleast one';
    let openModal = isItemsSelected;
    return { toastErrorMsg, openModal };
};

const categorizeAssets = {
    key: 'bulk_categorize_assets',
    menuName: 'Categorize Assets',
    menuIcon: 'category',
    onClick: (item, dispatch, { getSelectedData, customerId, defaultEmail, siteId }) => {
        let selectedData = getSelectedData();
        let { openModal, toastErrorMsg } = isToOpenBulkActions({ selectedData });
        let selectedCount = selectedData?.filter((asset) => asset.is_categorised === 0)?.length;
        if (openModal) {
            return dispatch(
                openCustomModal({
                    heading: 'Bulk Categorise Asset',
                    modalWidth: '60rem',
                    modalHeight: 'auto',
                    selectedCount,
                    modalComponent: <EditCategoriseAsset modalData={{ assetslist: selectedData }} customerId={customerId} siteId={siteId} />,
                })
            );
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: toastErrorMsg }));
        }
    },
    isDisabled: (item, { siteId }) => {
        return { disabled: siteId < 0, message: 'Invalid site' };
    },
};

const bulkMigrateAsset = {
    key: 'bulk_migrate_assets',
    menuName: 'Migrate Assets',
    menuIcon: 'move_up',
    onClick: (item, dispatch, { getSelectedData, customerId, siteId, warehouseId }) => {
        let selectedData = getSelectedData();
        const allowed = selectedData?.filter((asset) => canAssetMigrate(asset?.status));
        let { openModal, toastErrorMsg } = isToOpenBulkActions({ selectedData });
        if (!siteId) siteId = allowed?.[0]?.fk_site_id;
        if (!warehouseId) warehouseId = allowed?.[0]?.warehouse_id;
        if (openModal) {
            return dispatch(
                openCustomModal({
                    heading: 'Bulk Migrate Assets',
                    modalWidth: '60rem',
                    modalHeight: 'auto',
                    selectedCount: allowed.length,
                    modalComponent: <MigrateAsset modalData={{ assetslist: allowed }} customerId={customerId} warehouseId={warehouseId} siteId={siteId} />,
                })
            );
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: toastErrorMsg }));
        }
    },
    isDisabled: (item, { siteId, warehouseId, tab }) => {
        if (tab === assets?.id) {
            return { disabled: siteId < 0, message: 'Invalid site' };
        } else if (tab === spareAssets?.id) {
            return { disabled: warehouseId < 0, message: 'Invalid site' };
        }
    },
};

const releaseAssets = {
    menuName: 'Release Assets',
    menuIcon: 'highlight_off ',
    onClick: () => {
        alert('Release Assets');
    },
};

const bulkScrapAsset = {
    key: 'bulk_scrap_assets',
    menuName: 'Scrap Assets',
    menuIcon: 'highlight_off',
    onClick: (item, dispatch, { getSelectedData, customerId, defaultEmail, customerIdOfSelectedSite, siteId, warehouseId, tabName }) => {
        let selectedData = getSelectedData();
        let { openModal, toastErrorMsg } = isToOpenBulkActions({ selectedData });
        if (openModal) {
            return dispatch(
                openCustomModal({
                    heading: 'Scrap Assets',
                    modalWidth: '45rem',
                    modalHeight: 'auto',
                    selectedCount: selectedData?.length,
                    modalComponent: (
                        <ScrapAsset
                            modalData={{ assetslist: selectedData }}
                            customerId={customerId}
                            customerIdOfSelectedSite={customerIdOfSelectedSite}
                            warehouseId={warehouseId}
                            siteId={siteId}
                            modalName={tabName}
                        />
                    ),
                })
            );
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: toastErrorMsg }));
        }
    },
    isDisabled: (item, { siteId }) => {
        return { disabled: siteId < 0, message: 'Invalid site' };
    },
};

const bulkApproveRmaSupport = {
    key: 'bulk_approve_rma_support',
    menuName: 'Bulk Approve RMA Support',
    menuIcon: 'list',
    onClick: (item, dispatch, { getSelectedData, customerId, siteId }) => {
        let selectedData = getSelectedData();
        let { openModal, toastErrorMsg } = isToOpenBulkActions({ selectedData });
        if (openModal) {
            return dispatch(
                openCustomModal({
                    heading: 'Approve RMA Support',
                    modalWidth: '64rem',
                    modalHeight: 'auto',
                    modalComponent: (
                        <ApproveRmaSupport modalData={{ assetslist: selectedData }} customerId={customerId} rmaEligibility={'RMA_ELIGIBILITY_APPROVE'} siteId={siteId} />
                    ),
                })
            );
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: toastErrorMsg }));
        }
    },
    isDisabled: (item, { siteId }) => {
        return { disabled: siteId < 0, message: 'Invalid site' };
    },
};

const addAssetTypes = {
    key: 'add_asset_types',
    menuName: 'Add Asset Type',
    menuIcon: 'add',
    onClick: (item, dispatch, { siteId, customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Add Asset Type',
                modalWidth: '64rem',
                modalHeight: 'auto',
                modalComponent: <AddAssetTypes customerId={customerId} type='assets' siteId={siteId} />,
            })
        );
    },
    isDisabled: (item, { siteId }) => {
        return { disabled: siteId < 0, message: 'Invalid site' };
    },
};

const bulkUploadAsset = {
    key: 'bulk_upload_asset',
    menuName: 'Bulk Upload Asset',
    menuIcon: 'backup',
    onClick: (data, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Bulk Upload Asset',
                modalWidth: '64rem',
                modalHeight: 'auto',
                modalComponent: (
                    <UploadFile customerId={customerId} uploadFileAction={bulkUploadAssets} type={assets.id} filename={'bulk_upload_asset_sample'} showCustomerDropdown />
                ),
            })
        );
    },
};

const bulkUploadSpareAssetModal = {
    key: 'bulk_upload_spareasset',
    menuName: 'Bulk Upload Spare Asset',
    menuIcon: 'backup',
    onClick: (data, dispatch, { customerId, wareHouseId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Bulk Upload Spare Asset',
                modalWidth: '64rem',
                modalHeight: 'auto',
                modalComponent: (
                    <UploadFile
                        customerId={customerId}
                        uploadFileAction={bulkUploadSpareAssets}
                        type={spareAssets.id}
                        filename={'bulk_upload_spare_asset_sample'}
                        warehouseId={wareHouseId}
                        showCustomerDropdown
                    />
                ),
            })
        );
    },
};

const downloadReport = {
    key: 'download_report',
    menuName: 'Download Report',
    imgIcon: '/img/csvdownload.png',
    onClick: (item, dispatch, { getSelectedData, customerId, defaultEmail, tab, warehouseId, role, customerIdOfSelectedSite, siteId }) => {
        let customer_id = customerId && customerId ? customerId : customerIdOfSelectedSite;
        let selectedData = getSelectedData();
        let count = selectedData?.length > 0 ? selectedData.length : 'ALL';
        return dispatch(
            openCustomModal({
                heading: 'Download CSV',
                modalWidth: '44rem',
                modalHeight: 'auto',
                modalComponent: (
                    <DownloadReport
                        role={role}
                        customerId={customer_id}
                        defaultEmail={defaultEmail}
                        selectedData={selectedData}
                        tabName={tab}
                        warehouseId={warehouseId}
                        count={count}
                        siteId={siteId}
                    />
                ),
            })
        );
    },
};

export const getAssetTitle = (asset) => {
    return `${asset.assetType?.title} ${asset.serialNo ? `(${asset.serialNo})` : ''}`;
};

const getBulkSitesActions = (role, type) => {
    let actions = [];
    if (isScm(role, type)) actions = [archivedOrViewAllAssets, downloadReport, bulkUploadSite, bulkUploadAsset];
    if (isCluster(role, type)) actions = [archivedOrViewAllAssets, downloadReport, bulkUploadSite, bulkUploadAsset];
    if (isSuperVisor(role, type)) actions = [archivedOrViewAllAssets, downloadReport, bulkUploadSite, bulkUploadAsset];
    if (isCustomer(role, type)) actions = [archivedOrViewAllAssets, downloadReport, bulkUploadSite, bulkUploadAsset];
    if (isCustomerAdmin(role, type)) actions = [archivedOrViewAllAssets, downloadReport, bulkUploadSite, bulkUploadAsset];
    if (isCustomerUser(role, type)) actions = [archivedOrViewAllAssets, downloadReport, bulkUploadSite, bulkUploadAsset];
    return actions;
};

const getBulkAssetsActions = (role, type, { selectedSiteId }) => {
    let actions = [];
    if (isScm(role, type)) actions = [addAssetTypes, bulkScrapAsset, bulkMigrateAsset, categorizeAssets, archivedOrViewAllAssets, bulkUploadAsset, downloadBulkQR, downloadReport];
    else if (isCluster(role, type))
        actions = [addAssetTypes, bulkScrapAsset, bulkMigrateAsset, categorizeAssets, archivedOrViewAllAssets, bulkUploadAsset, downloadBulkQR, downloadReport];
    else if (isSuperVisor(role, type))
        actions = [addAssetTypes, bulkScrapAsset, bulkMigrateAsset, categorizeAssets, archivedOrViewAllAssets, bulkUploadAsset, downloadBulkQR, downloadReport];
    else if (isCustomer(role, type))
        actions = [
            addAssetTypes,
            bulkScrapAsset,
            bulkMigrateAsset,
            categorizeAssets,
            archivedOrViewAllAssets,
            bulkUploadAsset,
            bulkApproveRmaSupport,
            downloadBulkQR,
            downloadReport,
        ];
    else if (isCustomerAdmin(role, type))
        actions = [
            addAssetTypes,
            bulkScrapAsset,
            bulkMigrateAsset,
            categorizeAssets,
            archivedOrViewAllAssets,
            bulkUploadAsset,
            bulkApproveRmaSupport,
            downloadBulkQR,
            downloadReport,
        ];
    else if (isCustomerUser(role, type))
        actions = [addAssetTypes, bulkScrapAsset, bulkMigrateAsset, categorizeAssets, archivedOrViewAllAssets, bulkUploadAsset, downloadBulkQR, downloadReport];

    return actions;
};

const getBulkWarehouseActions = (role, type) => {
    let actions = [];
    if (isScm(role, type)) actions = [downloadReport];
    else if (isCluster(role, type)) actions = [downloadReport];
    else if (isSuperVisor(role, type)) actions = [downloadReport];
    else if (isCustomer(role, type)) actions = [downloadReport];
    else if (isCustomerAdmin(role, type)) actions = [downloadReport];
    else if (isCustomerUser(role, type)) actions = [downloadReport];
    else if (isPsp(role, type)) actions = [downloadReport];
    return actions;
};

const getBulkSpareAssetsActions = (role, type, { selectedWarehouseId }) => {
    let actions = [];
    if (isScm(role, type)) actions = [downloadBulkQR, bulkMigrateAsset, bulkUploadSpareAssetModal, downloadReport, createReconcilationTicketSpareAsset];
    else if (isCluster(role, type)) actions = [downloadBulkQR, bulkMigrateAsset, downloadReport, createReconcilationTicketSpareAsset];
    else if (isSuperVisor(role, type)) actions = [downloadBulkQR, bulkMigrateAsset, downloadReport, createReconcilationTicketSpareAsset];
    else if (isCustomer(role, type)) actions = [downloadBulkQR, bulkMigrateAsset, bulkUploadSpareAssetModal, downloadReport, createReconcilationTicketSpareAsset];
    else if (isCustomerAdmin(role, type)) actions = [downloadBulkQR, bulkMigrateAsset, bulkUploadSpareAssetModal, downloadReport, createReconcilationTicketSpareAsset];
    else if (isCustomerUser(role, type)) actions = [downloadBulkQR, bulkMigrateAsset, bulkUploadSpareAssetModal, downloadReport, createReconcilationTicketSpareAsset];
    else if (isPsp(role, type)) actions = [downloadBulkQR, bulkMigrateAsset, downloadReport, createReconcilationTicketSpareAsset];
    return actions;
};

export const sites = { title: 'Site Details', id: 'sites', singular: 'Site', path: 'sites', getBulkActions: getBulkSitesActions };
export const assets = { title: 'Asset Details', id: 'assets', singular: 'Asset', path: 'assets', getBulkActions: getBulkAssetsActions };
export const warehouses = {
    title: 'Warehouse Details',
    id: 'warehouse',
    singular: 'Warehouse',
    path: 'warehouse',
    getBulkActions: getBulkWarehouseActions,
};
export const spareAssets = {
    title: 'Spare Assets Details',
    id: 'spareAssets',
    singular: 'Spare Asset',
    path: 'spareAssets',
    getBulkActions: getBulkSpareAssetsActions,
};

const sitesTab = {
    tabName: 'Sites',
    subTabs: [sites, assets],
    tabIcon: 'people_alt',
    iconSize: 'text-3xl',
};

const sparesTab = {
    tabName: 'Spares',
    subTabs: [warehouses, spareAssets],
    tabIcon: 'people_alt',
    iconSize: 'text-3xl',
};

const getSitesTableActions = (role, type) => {
    if (isScm(role, type)) return [editSite, updateSla, addAsset, deleteSiteModal];
    else if (isCluster(role, type)) return [editSite, updateSla, addAsset, deleteSiteModal];
    else if (isSuperVisor(role, type)) return [editSite, updateSla, addAsset, deleteSiteModal];
    else if (isCustomer(role, type)) return [editSite, updateSla, addAsset, deleteSiteModal];
    else if (isCustomerAdmin(role, type)) return [editSite, updateSla, addAsset, deleteSiteModal];
    else if (isCustomerUser(role, type)) return [editSite, updateSla, addAsset, deleteSiteModal];
    else return;
};

const canAssetMigrate = (status) => {
    return status === 'AVAILABLE';
};

export const isDeviceConfigurationRequired = (asset) => {
    return asset.assetType?.device_config_required;
}

const getAssetsTableActions = (role, type) => {
    let actions = [];
    if (isScm(role, type)) actions = [editAsset, migrateAsset, downloadQR, deleteAsset, requestRmaSupport, editAssetConfig];
    else if (isCluster(role, type)) actions = [editAsset, migrateAsset, replaceAsset, categoriseAsset, downloadQR, scrapAsset, deleteAsset, requestRmaSupport, editAssetConfig];
    else if (isSuperVisor(role, type)) actions = [editAsset, migrateAsset, replaceAsset, categoriseAsset, downloadQR, scrapAsset, deleteAsset, requestRmaSupport];
    else if (isCustomer(role, type)) actions = [editAsset, migrateAsset, replaceAsset, approveRmaSupport, categoriseAsset, downloadQR, scrapAsset, deleteAsset, requestRmaSupport, editAssetConfig];
    else if (isCustomerAdmin(role, type)) actions = [editAsset, migrateAsset, replaceAsset, approveRmaSupport, categoriseAsset, downloadQR, scrapAsset, deleteAsset, editAssetConfig];
    else if (isCustomerUser(role, type)) actions = [editAsset, migrateAsset, replaceAsset, categoriseAsset, downloadQR, scrapAsset, deleteAsset, editAssetConfig];
    else actions = [];
    return actions;
};

const getWarehouseTableActions = (role, type, { userId, warehouse }) => {
    if (isScm(role, type)) return [editWarehouse, addSpareAsset, deleteWarehouseModal];
    else if (isCluster(role, type)) return [editWarehouse, deleteWarehouseModal];
    else if (isSuperVisor(role, type)) return [editWarehouse, deleteWarehouseModal];
    else if (isCustomer(role, type)) return [editWarehouse, addSpareAsset, deleteWarehouseModal];
    else if (isCustomerAdmin(role, type)) return [editWarehouse, addSpareAsset, deleteWarehouseModal];
    else if (isCustomerUser(role, type)) return [editWarehouse, addSpareAsset, deleteWarehouseModal];
    else if (isPsp(role, type)) return [editWarehouse, deleteWarehouseModal];
    else return;
};

const getSpareTableActions = (role, type, frontend_controller) => {
    let actions = [];

    if (isAssetActionsAllowed(frontend_controller)) {
        actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal, editAssetConfig];
    } else if (isScm(role, type)) actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal, editAssetConfig];
    else if (isCluster(role, type)) actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal, editAssetConfig];
    else if (isSuperVisor(role, type)) actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal];
    else if (isCustomer(role, type)) actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal];
    else if (isCustomerAdmin(role, type)) actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal];
    else if (isCustomerUser(role, type)) actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal];
    else if (isPsp(role, type)) actions = [editAssetModal, migrateAsset, downloadQR, deleteSpareAssetModal, editAssetConfig];


    actions = actions.filter((action) => {
        if (action.key === deleteSpareAssetModal.key) return !isSp(role, type) && !isIsp(role, type)
        return true;
    });

    return actions;
};

const canModifyWarehouse = (loggedUserId, warehouseUserId) => {
    return loggedUserId === warehouseUserId || warehouseUserId === 0;
};

const getInventoryMainTabs = (role, type) => {
    if (isScm(role, type)) return [sitesTab, sparesTab];
    else if (isCluster(role, type)) return [sparesTab];
    else if (isSuperVisor(role, type)) return [sparesTab];
    else if (isCustomer(role, type)) return [sitesTab, sparesTab];
    else if (isCustomerAdmin(role, type)) return [sitesTab, sparesTab];
    else if (isCustomerUser(role, type)) return [sitesTab, sparesTab];
    else if (isPsp(role, type)) return [sparesTab];
    else if (isSp(role, type) || isSpIsp(role, type)) return [sparesTab];
    else if (isFe(role, type)) return [sparesTab];
    else if (isIsp(role, type)) return [sparesTab];
    else return;
};

const getInventoryDefaultTab = (role, type, { urlQueryString }) => {
    const tabs = getInventoryMainTabs(role, type);
    let tab = tabs[0],
        subTab = tab.subTabs[0];
    if (urlQueryString) {
        const { matchingTab, matchingSubTab } = getTabFromQueryString(tabs, urlQueryString);
        if (matchingTab) {
            tab = matchingTab;
            subTab = matchingSubTab;
        }
    }
    return { rootTab: tab.tabName, subTab: subTab.id };
};

const getTabFromQueryString = (tabs, urlQueryString) => {
    const match = urlQueryString.match(/[\?&]tab=(\w+)/);
    let queryTab;
    if (match) queryTab = match[1];
    const matchingTab = tabs.find((tab) => tab.subTabs.find((subTab) => subTab.path === queryTab));
    if (matchingTab) {
        const matchingSubTab = matchingTab.subTabs.find((subTab) => subTab.path === queryTab);
        return { matchingTab, matchingSubTab };
    }
    return {};
};

const getInventoryBulkActionButton = (role, type) => {
    if (isScm(role, type) || isCustomerAdmin(role, type) || isCustomer(role, type) || isCluster(role, type) || isSuperVisor(role, type)) return true;
    else return false;
};

const getInventoryAddNewButton = (role, type) => {
    if (isScm(role, type) || isCustomerAdmin(role, type) || isCustomer(role, type)) return true;
    else return false;
};

export const GetInventoryActions = (logic, { asset, spareAsset, warehouse, tabName, urlQueryString } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type,
        userId = loggedUser.id,
        frontend_controller = loggedUser.frontend_controller;
    if (logic === 'sitesTableActions') return getSitesTableActions(role, type);
    if (logic === 'assetsTableActions') return getAssetsTableActions(role, type, asset);
    if (logic === 'warehouseTableActions') return getWarehouseTableActions(role, type, { userId, warehouse });
    if (logic === 'spareTableActions') return getSpareTableActions(role, type, frontend_controller);
    if (logic === 'tabs') return getInventoryMainTabs(role, type);
    if (logic === 'defaultTab') return getInventoryDefaultTab(role, type, { urlQueryString });
    if (logic === 'bulkActionButton') return getInventoryBulkActionButton(role, type);
    if (logic === 'addNewButton') return getInventoryAddNewButton(role, type, tabName);
    if (logic === 'isToShowCustomerData') return isScm(role, type);
    return [];
};

export const InventoryFormFields = (fieldName) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type;
    if (fieldName === 'addNewSiteCustomerField' || fieldName === 'addNewWarehouseCustomerField' || fieldName === 'addNewSpareAssetCustomerField') return isScm(role, type);
    if (fieldName === 'isToShowAddNewWarehouseDetails') return isCustomer(role, type);
    if (fieldName === 'downloadBulkQRCustomerField') return isScm(role, type);

    return false;
};

export const InventoryTableDetailsFields = (fieldName) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type;
    if (fieldName === 'customerCompanyNameInWarehouseTable') return isScm(role, type);
    return false;
};

export const defaultRowsPerPage = 10;
export const defaultPage = 1;
