import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export default function ReadMoreText({ text, className, numberOfLine = 6, buttonClass = 'pl-2' }) {
    const [clamped, setClamped] = useState(true);
    const [showButton, setShowButton] = useState(false);
    const containerRef = useRef(null);
    const handleClick = () => setClamped(!clamped);

    useEffect(() => {
        const hasClamping = (el) => {
            const { clientHeight, scrollHeight } = el;
            return clientHeight !== scrollHeight;
        };

        const checkButtonAvailability = () => {
            if (containerRef.current) {
                const hadClampClass = containerRef.current.classList.contains('clamp');
                if (!hadClampClass) setClamped(true);
                setShowButton(hasClamping(containerRef.current));
                if (!hadClampClass) setClamped(false);
            }
        };

        const debouncedCheck = debounce(checkButtonAvailability, 50);

        checkButtonAvailability();
        window.addEventListener('resize', debouncedCheck);

        return () => {
            window.removeEventListener('resize', debouncedCheck);
        };
    }, [containerRef]);

    return (
        <>
            <p ref={containerRef} className={`whitespace-pre-wrap ${className}  break-words  ${clamped ? `clamp line-clamp-${numberOfLine}` : ``}`}>
                {text}
            </p>

            <p onClick={() => handleClick()} className={`text-left text-font12 text-scogoorange cursor-pointer ${showButton ? 'block' : 'hidden'} ${buttonClass}`}>
                {clamped ? 'Read more' : ' Show less'}
            </p>
        </>
    );
}
